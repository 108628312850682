import AsyncLoadingButton from '@/components/form/asyncLoading/asyncLoadingButton';
import TextFieldInputLabel from '@/components/form/inputLabel';
import { useGraphqlResult } from '@/data/query/graphqlProvider';
import useUserInfo from '@/providers/auth/useUserInfo';
import { GatewayBase, Order } from '@/types/schema';
import { isProduction } from '@/utils/config';
import {
	ArrowBackIos as ArrowBackIosIcon,
	Payment as PaymentIcon
} from '@mui/icons-material';
import { Alert, MenuItem, Select, Skeleton, Stack } from '@mui/material';
import axios from 'axios';
import { useFormik } from 'formik';
import Script from 'next/script';
import { useSnackbar } from 'notistack';
import { Fragment, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAsyncEffect } from 'rooks';
import * as yup from 'yup';
import { getPaymentNote } from '../details';
import { makePayment } from '../helpers';
import MicroformField from './microformField';

type MicroformProps = {
	amount: number,
	tip?: number,
	dollarTip?: number,
	method: string,
	createPayment: ( data? ) => any,
	gateway: GatewayBase | null,
	cancel,
};

const achValidationSchema = yup.object( {
	accountNumber: yup
		.string()
		.required( 'Required' ),
	routingNumber: yup
		.string()
		.required( 'Required' ),
} );

function CyberSourceACH( { amount, createPayment, gateway, cancel }: MicroformProps ) {
	const microform = useRef<any>();
	const [ loading, setLoading ] = useState( true );
	const [ cybersourceError, setCybersourceError ] = useState( '' );
	const [ accountType, setAccountType ] = useState( 'C' );
	const { t } = useTranslation();
	
	const formik = useFormik( {
		initialValues: {
			routingNumber: '',
			accountNumber: '',
		},
		validationSchema: achValidationSchema,
		onSubmit        : async ( values ) => console.log( values ),
	} );
	
	async function onSubmit( e ) {
		e.preventDefault();
		console.log( 'Submittiing' );
		console.log( formik.values );
		console.log( microform.current.createToken );
		try {
			const token = await new Promise( ( resolve, reject ) => {
				microform.current.createToken( {
					accountType,
				}, ( err, token ) => err ? reject( err ) : resolve( token ) );
			} );
			console.log( token );
			console.log( microform.current.fields );
			setCybersourceError( '' );
			const data = await createPayment( {
				achToken: token,
			} );
			return data?.payment;
		} catch ( error ) {
			console.log( 'Error', error );
			setCybersourceError( 'There was a problem with the transaction, please try again.' );
		}
	}
	
	async function onLoad() {
		console.log( 'Cybersource loaded' );
		setLoading( false );
		
		const { data } = await axios.post( '/api/user/cybersource/captureContext/microform', {
			amount   : amount.toString(),
			gatewayId: gateway?.id,
		} );
		console.log( 'context', data );
		
		// @ts-expect-error Flex is loaded from the script
		const flex = new window.Flex( data.token );
		// ?? styles not working!!? Copy from https://developer.cybersource.com/docs/cybs/en-us/digital-accept-flex/developer/all/rest/digital-accept-flex/microform-integ-v2/microform-integ-getting-started-v2/flex-getting-started-examples-v2.html#flex-getting-started-examles-v2_checkout-pay-form
		const myStyles = {
			'input': {
				'font-size'  : '14px',
				'font-family': 'helvetica, tahoma, calibri, sans-serif',
				'color'      : 'black',
			},
			':focus'   : { color: 'blue' },
			':disabled': { cursor: 'not-allowed' },
			'valid'    : { color: '#3c763d' },
			'invalid'  : { color: '#a94442' },
		};
		const microformInstance = flex.microform( 'check', { styles: myStyles } );
		
		// https://developer.cybersource.com/docs/cybs/en-us/digital-accept-flex/developer/all/rest/digital-accept-flex/microform-integ-v2/api-reference-v2/class-microform-v2.html#class-microform-v2_clear
		const routingNumber = microformInstance.createField( 'routingNumber', { placeholder: 'Enter routing number' } );
		routingNumber.load( '#routing-number' );
		
		const accountNumber = microformInstance.createField( 'accountNumber', { placeholder: 'Enter account number' } );
		accountNumber.load( '#account-number' );
		
		const accountNumberConfirm = microformInstance.createField( 'accountNumberConfirm', { placeholder: 'Confirm account number' } );
		accountNumberConfirm.load( '#account-number-confirm' );
		
		// const accountType = microformInstance.createField('accountType', { placeholder: 'Select Account Type' });
		// accountType.load('#account-type');
		
		console.log( 'Instance', microformInstance );
		
		microform.current = microformInstance;
	}
	
	useAsyncEffect( async () => {
		// @ts-expect-error Flex is loaded from the script
		if ( !window.Flex ) return;
		await onLoad();
	}, [] );
	
	if ( loading ) return (
		<Fragment>
			<Skeleton
				animation='wave'
				height={100}
				sx={{ borderRadius: 1 }}
			/>
			<Script
				src={`https://${isProduction ? '' : 'test'}flex.cybersource.com/microform/bundle/v2/flex-microform.min.js`}
				onLoad={onLoad}
			/>
		</Fragment>
	);
	return (
		<Stack spacing={2} my={2} component='form' onSubmit={onSubmit}>
			<MicroformField label='Routing Number' containerProps={{ id: 'routing-number' }}/>
			<MicroformField label='Account Number' containerProps={{ id: 'account-number' }}/>
			<MicroformField label='Confirm Account Number' containerProps={{ id: 'account-number-confirm' }}/>
			<TextFieldInputLabel label='Account Type'/>
			<Select
				value={accountType}
				placeholder='Select Account Type'
				id='account-type'
				onChange={( e ) => {
					setAccountType( e.target.value );
				}}>
				<MenuItem value='C'>Checking</MenuItem>
				<MenuItem value='S'>Savings</MenuItem>
			</Select>
			{cybersourceError && <Alert severity='error'>{cybersourceError}</Alert>}
			<Stack spacing={1} direction='row' alignItems='center'>
				<AsyncLoadingButton startIcon={<ArrowBackIosIcon/>} variant='outlined' onClick={cancel}>
					{t( 'common:back' )}
				</AsyncLoadingButton>
				<AsyncLoadingButton
					variant='contained'
					color='primary'
					disabled={!formik.isValid}
					startIcon={<PaymentIcon/>}
					sx={{ width: 150 }}
					type='submit'>
					{t( 'commerce:finish' )}
				</AsyncLoadingButton>
			</Stack>
		</Stack>
	);
}

type AchProps = {
	amount: number,
	paymentGateway: GatewayBase | null,
	dollarTip?: number,
	tip?: number,
	prepayClientId?: string,
	invoiceNumber?: string,
	method: string,
	locationPayment?: boolean,
	invoiceId?: string
	cancel,
	confirm: ( paymentObject: any ) => Promise<void>,
};

export default function Wrapper( props: AchProps ) {
	const {
		method,
		amount,
		paymentGateway,
		tip, dollarTip,
		prepayClientId,
		invoiceNumber,
		locationPayment,
		invoiceId,
		cancel,
		confirm,
	} = props;
	const { t } = useTranslation();
	const { enqueueSnackbar } = useSnackbar();
	const { staff } = useUserInfo();
	const {
		id,
		company,
		client: orderClient,
		paidTotal,
		metadata,
		staff: orderStaff,
		companyLocation,
	} = useGraphqlResult<Order>();
	const [ clientAddress, setClientAddress ] = useState( orderClient?.addresses?.[ 0 ] );
	const gateway = paymentGateway;
	
	return <CyberSourceACH
		gateway={paymentGateway}
		amount={amount}
		dollarTip={dollarTip}
		method='ach'
		tip={tip}
		cancel={cancel}
		createPayment={async ( { achToken } ) => {
			enqueueSnackbar( t( 'commerce:payment-is-processing' ), { variant: 'info' } );
			const paymentData = await makePayment( {
				type  : 'ACH',
				amount: amount,
				fee   : 0,
				tip   : dollarTip || amount * ( tip ?? 0 ) / 100,
				note  : getPaymentNote( {
					invoiceNote: prepayClientId ? 'PrePaid' : invoiceNumber || '',
					method,
					locationPayment,
				} ),
				signature      : null,
				orderId        : invoiceId || id,
				gatewayId      : gateway?.id,
				companyId      : company.id,
				metadata       : company?.metadata,
				staffExternalId: staff?.externalId || orderStaff?.externalId || null,
				staffId        : staff?.id,
				payerId        : staff?.id || orderClient?.id,
				payerName      : orderClient?.name || orderClient?.email || orderClient?.contact || ( staff?.user?.firstName || '' + staff?.user?.lastName ),
				achToken,
				clientAddress  : clientAddress,
				invoiceNumber  : invoiceNumber,
			} );
			await confirm( {
				payment: paymentData?.payment,
				method,
			} );
		}}
	/>;
};