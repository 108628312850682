import { StorePublic } from '@/generated/graphql';
import { PLATFORM_ENDPOINT } from '@/pages/api/processor/clover';
import { Order, Store } from '@/types/schema';
import { getBrowserTimezone } from '@/utils/timezone';
import { gql } from '@apollo/client';
import axios from 'axios';
import { toLower } from 'lodash-es';
import process from 'process';

export const printAndSendOrder = async (
	invoice: Order,
	store: StorePublic,
) => {
	const timezone = getBrowserTimezone();
	
	console.log( { invoice } );
	// receipt to store company email and phone that their customer has made a purchase
	await axios.post( `${process.env.NEXT_PUBLIC_SERVER_URL}/api/emails/${store.id}/storeOrderReceipt`, {
		orderId: invoice.id,
		timezone,
	} ).catch( () => null );
	
	if ( store.cell || invoice.company?.cell ) {
		await axios.post( `${process.env.NEXT_PUBLIC_SERVER_URL}/api/message`, {
			company: invoice.company.id,
			number : store.cell || invoice.company.cell,
			message: `Hi ${invoice.company.name || invoice.company.contact || ''},\n
				You just received an order! ${invoice?.metadata?.customNumber || invoice?.number || invoice?.externalId}\n
				Order details: ${process.env.NEXT_PUBLIC_SERVER_URL}/p/${invoice.id}/${toLower( invoice.type )}\n
				`,
			timezone,
		} ).catch( () => null );
	}
	
	// receipt to client email that their order has been placed
	if ( invoice.client?.email ) {
		await axios.post( `${process.env.NEXT_PUBLIC_SERVER_URL}/api/emails/${store.id}/storeClientReceipt`, {
			orderId: invoice.id,
			timezone,
		} ).catch( () => null );
	}
	
	if ( invoice.client?.cell ) {
		await axios.post( `${process.env.NEXT_PUBLIC_SERVER_URL}/api/message`, {
			company: invoice.company.id,
			number : invoice.client.cell,
			message: `Hi ${invoice.client.name || invoice.client.contact || ''},\n
				You have an order from ${invoice.company.name || invoice.company.contact || ''}\n
				Order details: ${process.env.NEXT_PUBLIC_SERVER_URL}/p/${invoice.id}/${toLower( invoice.type )}\n
				`,
		} ).catch( () => null );
	}
	
	// print receipt for kitchen on Clover
	await axios.post( `${process.env.NEXT_PUBLIC_SERVER_URL}/api/notification/printNotification`, {
		orderId: invoice.id,
	} ).catch( () => null );
	
	// print receipt for Clover customer
	await axios.post( `${process.env.NEXT_PUBLIC_SERVER_URL}/api/notification/printCloverOrder`, {
		orderId  : invoice.id,
		gatewayId: invoice.gateway?.id,
	} ).catch( () => null );
	
};

export const GatewaysPublicRead_StorePayment = gql`
	query GatewaysPublicRead_StorePayment($options: FilterOptions) {
		gatewaysPublicRead(options: $options) {
			id
			active
			external
			achDisabled
			externalId
			cloverApiAccessKey
			paymentMethod
		}
	}
`;
