import { paperShadow } from '@/providers/theme/themes';
import { Payment } from '@/types/schema';
import { Box, useTheme } from '@mui/material';
import type { Card } from '@square/web-payments-sdk-types';
import React, { type MutableRefObject, useEffect, useMemo, useState } from 'react';

export default function CardConnectPaymentDetails( {
	method,
	createPayment,
	onSubmit,
}: {
	method: string,
	createPayment: ( data? ) => any,
	onSubmit: MutableRefObject<( () => Promise<Payment> )>
} ) {
	const [ token, setToken ] = useState<Card>();
	const [ expiry, setExpiry ] = useState<string>();
	const theme = useTheme();
	
	const iframeCSS = useMemo( () => {
		const customCSS = `
      input, select {
        border: 1px solid ${theme.palette.divider};
        border-radius: 7px;
        background-color: ${theme.palette.background.default};
        color: ${theme.palette.text.primary};
        font-family: ${theme.typography.fontFamily};
        box-shadow: ${theme.shadows[ paperShadow as any ]};
        padding: 8.5px 14px;
        margin: 8px 0;
      }
      label {
		  color: ${theme.palette.text.primary};
		  font-family: ${theme.typography.fontFamily};
		  font-size: 13.333px !important;
		  padding-top: 10px !important;
      }
      input:focus {
        border-color: ${theme.palette.primary.main};
        box-shadow: unset;
      }
    `;
		
		// URL-encode the CSS for inclusion in the iframe src
		return encodeURIComponent( customCSS ).replace( /%20/g, '+' );
	}, [ theme ] );
	
	useEffect( () => {
		const handleTokenReceived = ( event ) => {
			try {
				const tokenData = typeof event.data === 'string' ? JSON.parse( event.data ) : event.data;
				
				if ( tokenData.message || tokenData.token || tokenData.data ) {
					const cardToken = tokenData.message || tokenData.token || tokenData.data;
					setToken( cardToken );
					setExpiry( tokenData.expiry );
				} else {
					console.error( 'Token data does not contain a valid token.' );
				}
			} catch ( error ) {
				console.error( 'Error parsing token data:', error );
			}
		};
		
		window.addEventListener( 'message', handleTokenReceived, false );
		
		// Clean up the event listener
		return () => {
			window.removeEventListener( 'message', handleTokenReceived, false );
		};
	}, [] );
	
	switch ( method ) {
		case 'card':
			onSubmit.current = async () => {
				if ( !token ) throw new Error( 'Please check card details then try again!' );
				const { payment } = await createPayment( { cardId: token, cardExpiry: expiry } );
				return payment;
			};
			
			const iframeSrc = `${process.env.NEXT_PUBLIC_CARDCONNECT_ACH_API_URL}/itoke/ajax-tokenizer.html?css=${iframeCSS}&tokenizewheninactive=true&formatinput=true&useexpiry=true&usecvv=true&invalidcreditcardevent=true&cardinputmaxlength=19`;
			return (
				<Box sx={{ backgroundColor: theme.palette.background.paper }}>
					<form name='tokenform' id='tokenform'>
						<iframe
							id='tokenFrame'
							name='tokenFrame'
							src={iframeSrc}
							frameBorder='0'
							scrolling='no'
							style={{ width: '100%', height: '220px' }}>
						</iframe>
						<input type='hidden' name='mytoken' id='mytoken'/>
					</form>
				</Box>
			);
		case 'ach':
		default:
			return null;
	}
}
