import SubscriptionModal from '@/baseline/subscription';
import UpgradeIcon from '@/baseline/subscription/upgradeIcon';
import useTierPermission from '@/baseline/subscription/useTierPermission';
import CustomCheckBox from '@/components/customCheckBox';
import Attachment from '@/components/fileUploading/attachment';
import AsyncLoadingButton from '@/components/form/asyncLoading/asyncLoadingButton';
import { SimpleGooglePlacesSearch } from '@/components/form/fields/googlePlacesSearch';
import LargeChip from '@/components/largeChip';
import { mutateGraphQL, queryGraphQL } from '@/data/apollo';
import { useGraphqlResult } from '@/data/query/graphqlProvider';
import {
	EmployeeUsageTotalSpentMutation,
	EmployeeUsageTotalSpentMutationVariables,
	ValidateEmployeeCardNumberQuery,
	ValidateEmployeeCardNumberQueryVariables,
} from '@/generated/graphql';
import SignModal from '@/modals/sign';
import Ach from '@/pages/dashboard/commerce/payment/ach';
import CardConnectPaymentDetails from '@/pages/dashboard/commerce/payment/cardConnect';
import CyberSourceMicroform from '@/pages/dashboard/commerce/payment/cybersource/microform';
import VopayPaymentDetails from '@/pages/dashboard/commerce/payment/vopay';
import { ClientInfo } from '@/pages/p/commerce/clientInfoForm';
import { useUpdateOnlineStoreAtom } from '@/pages/p/store/context';
import { surchargeFeeAtom } from '@/pages/settings/cards';
import useUserInfo from '@/providers/auth/useUserInfo';
import { useModal } from '@/providers/modal';
import { ClientCredit, GatewayBase, Order, Payment, Price } from '@/types/schema';
import postCloverMeteredBilling from '@/utils/api/postCloverMeteredBilling';
import wait from '@/utils/wait';
import { gql } from '@apollo/client';
import {
	ArrowBackIos as ArrowBackIosIcon,
	Assignment as AssignmentIcon,
	Payment as PaymentIcon,
} from '@mui/icons-material';
import {
	Box,
	Button,
	Grid,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	Stack,
	TextField,
	Typography,
} from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers-pro';
import { useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { useAtomValue } from 'jotai/index';
import { camelCase, toLower } from 'lodash-es';
import { useRouter } from 'next/router';
import { useSnackbar } from 'notistack';
import { ComponentType, Fragment, MutableRefObject, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 } from 'uuid';

import CloverPaymentDetails from './clover/details';
import CyberSourceACH from './cybersource/microformAch';
import {
	cardValidation,
	deleteStoreOrderOnPaymentFailure,
	makePayment,
	makePaymentWithClientPayment,
} from './helpers';
import SquarePaymentDetails from './square/details';
import StripePaymentDetails from './stripe/details';

export const cardFeePresent = ( prices: Price[] ) => prices?.find( ( price ) => price.name === 'Card Processing Fee' );

const disableFinishIfCard = ( method: string, required: boolean, signature: string, companyRequireSignature: boolean,
	orderRequireSignature: boolean ) => required && ( !signature && companyRequireSignature && orderRequireSignature && method === 'card' );

const inHouseAchGateway = [ 'CARD_CONNECT', 'VOPAY' ];

export const getPaymentNote = ( {
	invoiceNote, note = '', method, locationPayment, checkNumber = '', prepayClientId,
}: {
	invoiceNote: string,
	note?: string,
	method?: string,
	locationPayment?: boolean,
	checkNumber?: string,
	prepayClientId?: string,
} ) => {
	const baseNote = !prepayClientId && invoiceNote ? `${invoiceNote} - ${note}` : note;
	const suffix = prepayClientId ? '/Prepaid'
		: method === 'Invoice Credit' ? '/Credit'
			: locationPayment
				? '/Payment Request'
				: checkNumber
					? `#${checkNumber}`
					: '';
	return `${baseNote} ${suffix}`;
};

type Props = {
	amount: number,
	locationPaymentAmount?: number,
	cardFee?,
	tip?,
	dollarTip?,
	method,
	cancel,
	confirm: ( paymentObject: any ) => Promise<void>,
	locationPayment?: boolean,
	hideSignature?: boolean,
	client?: ClientInfo,
	storeOrder?: boolean,
	order?: Order,
	setOrder?: ( order: Order ) => void,
	prepayClientId?: string,
	required?: boolean,
	paymentCreationDate?: Date | undefined,
	setPaymentCreationDate?: ( date: Date | null ) => void | undefined,
	cardToken?: string,
	cardType?: string,
	paymentGateway: GatewayBase | null,
	selectedCredits?: ClientCredit[],
	selectedClientPayment?: Payment,
	selectedScheduledPayments?: Record<string, boolean>,
	closeDrawer?: () => void,
	cardFeeAllowedForStore?: boolean,
	invoiceNumber?: string,
	invoiceId?: string,
	scheduledPaymentId?: string
};

function PaymentDetails( {
	amount,
	locationPaymentAmount,
	cardFee = 0,
	tip,
	dollarTip,
	method,
	cancel,
	confirm,
	locationPayment,
	hideSignature,
	client,
	storeOrder,
	order,
	prepayClientId,
	setOrder,
	required = false,
	paymentCreationDate,
	setPaymentCreationDate,
	cardToken,
	cardType,
	paymentGateway,
	selectedCredits,
	selectedClientPayment,
	selectedScheduledPayments,
	closeDrawer,
	cardFeeAllowedForStore,
	invoiceNumber,
	invoiceId,
	scheduledPaymentId,
}: Props ) {
	const {
		id,
		gateway: _gateway,
		company,
		client: orderClient,
		paidTotal,
		metadata,
		externalId,
		type,
		staff: orderStaff,
		payments,
		companyLocation,
	} = useGraphqlResult<Order>();
	const { showModal } = useModal();
	const { enqueueSnackbar } = useSnackbar();
	const { staff, user } = useUserInfo();
	const router = useRouter();
	const setOnlineStoreAtom = useUpdateOnlineStoreAtom();
	const subscriptionIsValid = useTierPermission( 'SAVED_CARDS' );
	const route = router.route.split( '/' )[ 1 ];
	const isClientPage = route === 'p' || route === 'client';
	const gateway = paymentGateway;
	
	const paymentType = method.toUpperCase();
	const payByTender = ![ 'CARD',
		'ACH', 'INVOICE CREDIT' ].includes( paymentType ) && !paymentType?.includes( 'SAVED' );
	
	const cloverOrderGateway = externalId ? gateway || _gateway : null;
	
	const [ saveCard, setSaveCard ] = useState( false );
	const [ checkNumber, setCheckNumber ] = useState( '' );
	const [ memberCardNumber, setMemberCardNumber ] = useState( '' );
	const [ note, setNote ] = useState( '' );
	const [ payerLine1, setPayerLine1 ] = useState( '' );
	const [ payerCity, setPayerCity ] = useState( '' );
	const [ payerState, setPayerState ] = useState( '' );
	const [ payerPostalCode, setPayerPostalCode ] = useState( '' );
	const [ cardHolderName, setCardHolderName ] = useState( staff
		? orderClient?.name || orderClient?.contact || ''
		: '' );
	const [ cardHolderPhone, setCardHolderPhone ] = useState( staff ? orderClient?.phone || '' : '' );
	const [ cardFirstSix, setCardFirstSix ] = useState( '' );
	const [ cardLastFour, setCardLastFour ] = useState( '' );
	const [ invoiceNote, setInvoiceNote ] = useState( prepayClientId ? 'PrePaid' : invoiceNumber || '' );
	const [ signature, setSignature ] = useState<string | undefined>( undefined );
	const clientAddress = orderClient?.addresses?.[ 0 ];
	
	const onSubmit = useRef<() => Promise<string>>();
	const queryClient = useQueryClient();
	const { t } = useTranslation();
	const surchargeFeePercent = useAtomValue( surchargeFeeAtom );
	const surchargePercent = ( method === 'card' || method?.includes( 'saved' ) ) && cardType !== 'debit'
		? surchargeFeePercent
		: 0;
	const paymentCardFee = cardType === 'debit' || ( surchargePercent || 0 ) > 0 ? 0 : cardFee;
	const cashDiscount = paidTotal === 0 && metadata?.enableCashDiscount && ( company.metadata?.cashDiscount || 0 ) / 100;
	const hasCardPayment = payments?.find( ( payment ) => [
		'card',
		'debit',
		'debitcard',
		'creditcard',
	].includes( toLower( camelCase( payment?.type ) ) ) );
	
	const PaymentDetails: ComponentType<{
		gateway: GatewayBase | null,
		amount: number,
		tip: number,
		dollarTip: number,
		method: string,
		createPayment: ( data? ) => any,
		onSubmit: MutableRefObject<( () => Promise<string> )>,
		confirm: ( payment: Payment ) => void
	}> = {
		CLOVER      : CloverPaymentDetails,
		SQUARE      : SquarePaymentDetails,
		STRIPE      : StripePaymentDetails,
		CARD_CONNECT: CardConnectPaymentDetails,
		PROPELR     : CyberSourceMicroform,
	}[ gateway?.external || '' ];
	
	const disableFinish = disableFinishIfCard( method, required, signature || '', company.metadata?.requireSignature, metadata?.requireSignature );
	
	let newLocationOrder: Order;
	return (
		<Stack spacing={2}>
			{PaymentDetails && (
				<PaymentDetails
					gateway={gateway}
					amount={amount}
					tip={tip}
					dollarTip={dollarTip}
					method={method}
					createPayment={async ( args ) => {
						let paymentData;
						enqueueSnackbar( t( 'commerce:payment-is-processing' ), { variant: 'info' } );
						// this is the payment from p/payment.page.tsx i.e; payment request
						if ( locationPayment ) {
							
							if ( isClientPage && payByTender ) throw new Error( 'Invalid payment method.' );
							
							try {
								if ( invoiceId?.length ) {
									const { data: commerce } = await axios.post( '/api/fetchCommerce', {
										id: invoiceId,
									} );
									newLocationOrder = commerce?.commerceRead;
									
									if ( !surchargePercent && cardFee && method === 'card' && cardType !== 'debit' && newLocationOrder.company?.metadata?.cardFee > 0 ) {
										await axios.post( '/api/tempCardFee', {
											id     : newLocationOrder.id,
											cardFee,
											company: company.id,
											paying : amount,
										} );
									}
								} else {
									const { data } = await axios.post( '/api/management/createClient', {
										...client,
										company: company.id,
									} );
									
									// creating an order behind the scene for the payment because clover needs an order to apply a payment
									const { data: newOrder } = await axios.post( '/api/orderPublicWrite', {
										companyId: company.id,
										client   : data.clientWrite.id || client?.id,
										gateway  : gateway?.id,
										lineItems: [ {
											id      : v4(),
											name    : 'Payment Request',
											price   : locationPaymentAmount,
											unit    : 'Rate',
											quantity: 1,
										} ],
										notes: getPaymentNote( {
											invoiceNote,
											note,
											prepayClientId,
											locationPayment,
										} ),
										metadata: {
											disableHousePayment: true,
										},
									} );
									newLocationOrder = newOrder?.orderWrite;
									
									try {
										if ( newLocationOrder?.id ) {
											
											// sync the order so that payment can go on it on clover
											if ( gateway?.external === 'CLOVER' || companyLocation?.gateway?.external === 'CLOVER' ) {
												const { data: cloverOrder } = await axios.post( '/api/processor/manage/postOrder', {
													id     : newLocationOrder.id,
													company: company.id,
													staffId: staff?.id,
												} );
												setOrder?.( cloverOrder?.commerce );
												newLocationOrder = cloverOrder?.commerce;
											}
											
											// apply card fee
											if ( !surchargePercent && !prepayClientId && cardFee && method === 'card' && cardType !== 'debit' && newLocationOrder.company?.metadata?.cardFee > 0 ) {
												await axios.post( '/api/tempCardFee', {
													id     : newOrder.orderWrite.id,
													cardFee,
													company: company.id,
													paying : amount,
												} );
											}
											
										}
									} catch ( e ) {
										try {
											// delete location order
											if ( newLocationOrder ) {
												if ( newLocationOrder?.externalId ) await axios.post( '/api/processor/manage/deleteOrder', { id: newLocationOrder.id } );
												
												await axios.post( '/api/orderPublicWrite', {
													id       : newLocationOrder.id,
													remove   : true,
													companyId: company.id,
												} );
												await queryClient.invalidateQueries( [ 'order' ] );
												
											}
										} catch {
										}
										closeDrawer?.();
										enqueueSnackbar( t( 'commerce:something-went-wrong-pay-again' ), { variant: 'error' } );
										return;
									}
								}
								// make a payment on the order that was synced
								if ( newLocationOrder?.id ) {
									paymentData = await makePayment( {
										type  : paymentType,
										amount: amount,
										fee   : prepayClientId ? 0 : paymentCardFee,
										tip   : prepayClientId ? 0 : dollarTip || amount * tip / 100,
										note  : getPaymentNote( {
											invoiceNote,
											note,
											method,
											locationPayment,
											checkNumber,
											prepayClientId,
										} ),
										signature      : signature,
										orderId        : newLocationOrder.id,
										gatewayId      : gateway?.id,
										companyId      : newLocationOrder.company.id,
										metadata       : newLocationOrder.company.metadata,
										staffExternalId: staff?.externalId || orderStaff?.externalId || null,
										staffId        : staff?.id,
										cardHolderName : cardHolderName,
										cardHolderPhone: cardHolderPhone,
										cardFirstSix   : cardFirstSix,
										cardLastFour   : cardLastFour,
										isPaymentLink  : !invoiceId,
										payerId        : newLocationOrder.client?.id || staff?.id,
										payerName      : newLocationOrder.client?.name || user?.firstName,
										saveCard       : saveCard,
										args           : args,
										isClientPage   : isClientPage,
										invoiceNumber  : invoiceNumber,
										scheduledPaymentId,
									} );
									
								}
							} catch ( e ) {
								// Don't delete if invoiceId is passed, it means it's a real invoice
								if ( newLocationOrder && !invoiceId ) {
									if ( newLocationOrder.externalId ) await axios.post( '/api/processor/manage/deleteOrder', { id: newLocationOrder.id } );
									await axios.post( '/api/orderPublicWrite', {
										id       : newLocationOrder.id,
										remove   : true,
										companyId: company.id,
									} );
									await queryClient.invalidateQueries( [ 'order' ] );
									
								}
								const cloverErrors = e?.response?.data?.cloverErrors || e?.cloverErrors;
								if ( cloverErrors ) {
									throw typeof cloverErrors === 'string'
										? cloverErrors
										: cloverErrors?.error?.message || cloverErrors?.message || 'An error has occurred. Clover.com';
								} else {
									enqueueSnackbar( t( 'commerce:something-went-wrong' ), { variant: 'default' } );
									closeDrawer?.();
									return;
								}
							}
							
						} else {
							let cloverOrder;
							// if clover gateway, sync the order if not already synced
							if ( !externalId && ( gateway?.external === 'CLOVER' || companyLocation?.gateway?.external === 'CLOVER' ) ) {
								try {
									const { data } = await axios.post( '/api/processor/manage/postOrder', {
										id,
										staffId  : staff?.id,
										gatewayId: gateway?.id || companyLocation?.gateway?.id,
									} );
									cloverOrder = data?.commerce;
									setOrder?.( data?.commerce );
									await wait( 800 );
								} catch ( e ) {
									throw e?.response?.data?.cloverErrors || 'Error syncing order.';
								}
								
							}
							
							// apply card fee
							if ( !surchargePercent && !metadata?.enableCardFee && method === 'card' && cardType !== 'debit' && company.metadata.cardFee > 0 && !metadata?.cardFee && ( !storeOrder || storeOrder && cardFeeAllowedForStore ) ) {
								try {
									const { data: commerce } = await axios.post( '/api/tempCardFee', {
										id,
										cardFee,
										storeOrder,
										paying: amount,
									} );
									
									if ( commerce.commerce ) {
										cloverOrder = commerce.commerce;
										if ( storeOrder ) {
											setOnlineStoreAtom( { atomOrderId: commerce.commerce.id } );
										}
										
										paymentData = await makePayment( {
											type           : method.toUpperCase(),
											amount         : amount,
											fee            : paymentCardFee,
											tip            : dollarTip || amount * tip / 100,
											note           : getPaymentNote( { invoiceNote, note } ),
											signature      : signature,
											orderId        : cloverOrder?.id || id,
											gatewayId      : gateway?.id,
											companyId      : company.id,
											metadata       : company?.metadata,
											staffExternalId: staff?.externalId || orderStaff?.externalId || null,
											staffId        : staff?.id,
											cardHolderName : cardHolderName,
											cardHolderPhone: cardHolderPhone,
											cardFirstSix   : cardFirstSix,
											cardLastFour   : cardLastFour,
											payerId        : staff?.id || orderClient?.id || cloverOrder?.staff?.id || cloverOrder?.client?.id || commerce.commerce?.client?.id,
											payerName      : user?.firstName || orderClient?.name || cloverOrder?.staff?.user?.firstName || cloverOrder?.client?.name || commerce.commerce?.client?.name,
											saveCard       : saveCard,
											args           : args,
											isClientPage   : isClientPage,
											invoiceNumber  : invoiceNumber,
											selectedScheduledPayments,
										} );
									}
									
								} catch ( e ) {
									
									// delete order on clover and set externalId to null
									if ( storeOrder ) {
										await deleteStoreOrderOnPaymentFailure( cloverOrder?.id || id, queryClient, cloverOrder?.company?.id || company?.id );
									}
									const cloverErrors = e?.response?.data?.cloverErrors || e?.cloverErrors;
									
									if ( cloverErrors ) {
										throw typeof cloverErrors === 'string'
											? cloverErrors
											: cloverErrors?.error?.message || cloverErrors?.message || 'An error has occurred. Clover.com';
									} else {
										enqueueSnackbar( t( 'commerce:something-went-wrong' ), { variant: 'default' } );
									}
								}
							} else {
								
								try {
									if ( method === 'ach' && cloverOrderGateway?.external === 'CLOVER' ) {
										const { data } = await axios.post( '/api/processor/payment/getTenders', {
											id: cloverOrderGateway.id,
										} );
										const bankTransferTender = data?.elements?.find( ( tender ) => tender.label === 'Bank Transfer' );
										if ( !bankTransferTender ) {
											await axios.post( '/api/processor/payment/postTender', {
												id     : cloverOrderGateway.id,
												label  : 'Bank Transfer',
												visible: false,
												enabled: true,
											} );
										}
									}
								} catch {
								}
								try {
									paymentData = await makePayment( {
										type           : method.toUpperCase(),
										amount         : amount,
										fee            : paymentCardFee,
										tip            : dollarTip || amount * tip / 100,
										note           : getPaymentNote( { invoiceNote, note } ),
										signature      : signature,
										orderId        : id,
										gatewayId      : gateway?.id,
										companyId      : company.id,
										metadata       : company?.metadata,
										staffExternalId: staff?.externalId || orderStaff?.externalId || null,
										staffId        : staff?.id,
										cardHolderName : cardHolderName,
										cardHolderPhone: cardHolderPhone,
										cardFirstSix   : cardFirstSix,
										cardLastFour   : cardLastFour,
										payerId        : staff?.id || orderClient?.id,
										payerName      : user?.firstName || orderClient?.name,
										saveCard       : saveCard,
										args           : args,
										isClientPage   : isClientPage,
										invoiceNumber  : invoiceNumber,
										selectedScheduledPayments,
									} );
									
								} catch ( e ) {
									// delete order on clover and set externalId to null
									if ( storeOrder ) {
										await deleteStoreOrderOnPaymentFailure( id, queryClient, company?.id );
									}
									const cloverErrors = e?.response?.data?.cloverErrors || e?.cloverErrors;
									
									if ( cloverErrors ) {
										throw typeof cloverErrors === 'string'
											? cloverErrors
											: cloverErrors?.error?.message || cloverErrors?.message || 'An error has occurred. Clover.com';
									} else {
										enqueueSnackbar( e, { variant: 'default' } );
									}
								}
							}
						}
						
						if ( paymentData && ( method === 'card' || method === 'ach' ) ) {
							if ( paymentData.declined ) {
								enqueueSnackbar( paymentData.message, { variant: 'error' } );
							} else {
								try {
									if ( gateway?.external === 'CLOVER' || newLocationOrder?.gateway?.external === 'CLOVER' || companyLocation?.gateway?.external === 'CLOVER' ) {
										if ( locationPayment && newLocationOrder ) {
											await postCloverMeteredBilling(
												{
													orderId  : newLocationOrder.id,
													gatewayId: gateway?.id || newLocationOrder?.gateway?.id,
													eventType: 'LocationPayment',
													key      : storeOrder
														? 'onlineStoreOrders'
														: method === 'card' ? 'invoicePayment' : 'bankAccount',
													staffId : staff?.id,
													clientId: newLocationOrder?.client?.id || orderClient?.id,
												} ).catch( () => [] );
										} else {
											await postCloverMeteredBilling( {
												orderId  : id,
												gatewayId: gateway?.id,
												eventType: method === 'card' ? 'Invoiss Payment' : 'Bank Account',
												key      : storeOrder
													? 'onlineStoreOrders'
													: method === 'ach' ? 'bankAccount' : !hasCardPayment ? 'invoicePayment'
														: 'partialPayments',
												staffId : staff?.id,
												clientId: newLocationOrder?.client?.id || orderClient?.id,
											} ).catch( () => [] );
										}
									}
									
								} catch {
								}
							}
						}
						
						if ( paymentData ) return paymentData;
					}}
					confirm={confirm}
					onSubmit={onSubmit as any}
				/>
			)}
			{company.metadata?.cardInfo && method === 'card'
				&& (
					<Stack direction='row' spacing={1}>
						<TextField
							fullWidth
							placeholder={t( 'common:card-holder-name' )}
							value={cardHolderName}
							onChange={( e ) => setCardHolderName( e.target.value )}
						/>
						<TextField
							fullWidth
							placeholder={t( 'common:phone' )}
							value={cardHolderPhone}
							onChange={( e ) => setCardHolderPhone( e.target.value )}
						/>
					</Stack>
				)}
			{PaymentDetails && method === 'card' && orderClient?.email && !storeOrder && !inHouseAchGateway.includes( gateway?.external || '' ) && gateway?.external !== 'PROPELR' && (
				<Fragment>
					{subscriptionIsValid ? (
						<ListItemButton
							onClick={() => setSaveCard( !saveCard )}>
							<ListItemIcon sx={{ alignSelf: 'start', mt: .5, minWidth: 40 }}>
								<CustomCheckBox checked={saveCard}/>
							</ListItemIcon>
							<ListItemText
								primary={(
									<Stack direction='row' alignItems='center' spacing={1}>
										<Typography>
											{t( 'commerce:save-card-for-future-use' )}
										</Typography>
									</Stack>
								)}
								secondary={!router.pathname.split( '/' ).includes( 'p' )
									? t( 'commerce:client-notified-by-merchant' )
									: t( 'commerce:cards-accessible-by-company' )}
							/>
						</ListItemButton>
					) : !required ? (
						<ListItemButton
							onClick={() => showModal( SubscriptionModal, { variant: 'fullPageDialog' } )}>
							<ListItemIcon sx={{ alignSelf: 'start', mt: .5, minWidth: 40 }}>
								<UpgradeIcon permission='SAVED_CARDS'/>
							</ListItemIcon>
							<ListItemText
								primary={(
									<Stack direction='row' alignItems='center' spacing={1}>
										<Typography>
											{t( 'commerce:save-card-for-future-use' )}
										</Typography>
									</Stack>
								)}
								secondary={!router.pathname.split( '/' ).includes( 'p' )
									? t( 'commerce:client-notified-by-merchant' )
									: t( 'commerce:cards-accessible-by-company' )}
							/>
						</ListItemButton>
					) : null}
					{saveCard && (
						<Stack direction='row' spacing={1} sx={{ m: 1 }}>
							<TextField
								fullWidth
								placeholder={t( 'common:first-six-digit' )}
								sx={{ width: '40%' }}
								inputProps={{ maxLength: 6, minLength: 6 }}
								value={cardFirstSix}
								onChange={( e ) => setCardFirstSix( e.target.value )}
							/>
							<Box sx={{ m: 'auto !important' }}>
								<Typography sx={{ whiteSpace: 'nowrap', textAlign: 'justify' }}>* * * * * *</Typography>
							</Box>
							<TextField
								fullWidth
								placeholder={t( 'common:last-four-digit' )}
								sx={{ width: '30%' }}
								inputProps={{ maxLength: 4, minLength: 4 }}
								value={cardLastFour}
								onChange={( e ) => setCardLastFour( e.target.value )}
							/>
						</Stack>
					)}
				</Fragment>
			)}
			{storeOrder && method === 'invoiss' && (
				<TextField
					fullWidth
					label='Member Card Number'
					placeholder='Member Card Number'
					value={memberCardNumber}
					onChange={( e ) => setMemberCardNumber( e.target.value )}
				/>
			)}
			{method === 'check' && (
				<TextField
					fullWidth
					placeholder={t( 'commerce:check-number' )}
					value={checkNumber}
					onChange={( e ) => setCheckNumber( e.target.value )}
				/>
			)}
			{( client || invoiceNumber ) && (
				<TextField
					fullWidth
					required
					disabled={Boolean( invoiceNumber ) || Boolean( prepayClientId )}
					helperText={invoiceNumber
						? t( 'commerce:invoice-number' )
						: t( 'commerce:what-is-this-payment-for' )}
					placeholder='e.g. E07HSGT-0012'
					inputProps={{ maxLength: 20, minLength: 1 }}
					value={invoiceNote}
					onChange={( e ) => setInvoiceNote( e.target.value )}
				/>
			)}
			{
				gateway?.external === 'CARD_CONNECT' && method === 'card' && (
					<Box>
						<Typography mb={1}>Billing address</Typography>
						<Grid container spacing={1}>
							<Grid item xs={12}>
								<SimpleGooglePlacesSearch
									value={payerLine1}
									setValue={setPayerLine1}
									placeholder='Line Address'
									onSelect={( address ) => {
										if ( address ) {
											setPayerLine1( address.line1 || '' );
											setPayerCity( address.city || '' );
											setPayerState( address.state || '' );
											setPayerPostalCode( address.postalCode || '' );
										}
									}}
								/>
							</Grid>
							<Grid item xs={12} sm={5}>
								<TextField
									fullWidth
									required
									placeholder='City'
									value={payerCity}
									rows={4}
									inputProps={{ maxLength: 50, minLength: 1 }}
									onChange={( e ) => setPayerCity( e.target.value )}
								/>
							</Grid>
							<Grid item xs={12} sm={3}>
								<TextField
									fullWidth
									required
									placeholder='State'
									value={payerState}
									rows={4}
									inputProps={{ maxLength: 50, minLength: 1 }}
									onChange={( e ) => setPayerState( e.target.value )}
								/>
							</Grid>
							<Grid item xs={12} sm={4}>
								<TextField
									fullWidth
									required
									placeholder='Zip Code'
									value={payerPostalCode}
									rows={4}
									inputProps={{ maxLength: 10, minLength: 1 }}
									onChange={( e ) => setPayerPostalCode( e.target.value )}
								/>
							</Grid>
						</Grid>
					</Box>
				)
			}
			{method !== 'ach' && (
				<Box>
					{client ? (
						<TextField
							fullWidth
							multiline
							required
							placeholder={t( 'commerce:note-placeholder' )}
							value={note}
							rows={4}
							inputProps={{ maxLength: 127, minLength: 1 }}
							onChange={( e ) => setNote( e.target.value )}
						/>
					) : (
						<TextField
							fullWidth
							multiline
							placeholder={t( 'common:note' )}
							value={note}
							rows={4}
							inputProps={{ maxLength: 127 }}
							onChange={( e ) => setNote( e.target.value )}
						/>
					)}
				</Box>
			)}
			{paymentCreationDate && setPaymentCreationDate && (
				<DateTimePicker<Date>
					ampm
					ampmInClock
					closeOnSelect
					disablePast
					label='Payment Creation Date'
					value={paymentCreationDate}
					timeSteps={{ minutes: 1 }}
					onChange={( date ) => setPaymentCreationDate( date )}
				/>
			)}
			<Attachment
				removeDownload
				src={signature}
				imageSX={{ width: '100%', height: 'unset', objectFit: 'cover' }}
			/>
			{company.metadata?.requireSignature && metadata?.requireSignature && method === 'card' && !signature && required && (
				<LargeChip label={t( 'commerce:signature-required' )} color='warning'/>
			)}
			<Stack spacing={1} direction='row' alignItems='center'>
				<AsyncLoadingButton startIcon={<ArrowBackIosIcon/>} variant='outlined' onClick={cancel}>
					{t( 'common:back' )}
				</AsyncLoadingButton>
				{!hideSignature && !client && (
					<Button
						variant='outlined'
						color='primary'
						startIcon={<AssignmentIcon/>}
						onClick={() => showModal( SignModal, { fullPageBottomSheet: false }, {
							onSave   : setSignature,
							invoiceId: id,
						} )}>
						{t( 'commerce:add-signature' )}
					</Button>
				)}
				<AsyncLoadingButton
					variant='contained'
					color='primary'
					disabled={disableFinish}
					startIcon={<PaymentIcon/>}
					sx={{ width: 150 }}
					onClick={async () => {
						// if method is invoice_me
						if ( storeOrder && method === 'invoice_me' ) {
							return await confirm( { payment: undefined, method, syncedOrder: order, paymentCreationDate } );
						} else if ( onSubmit.current ) { // pay by card and ach iframe
							if ( isClientPage && payByTender ) throw new Error( 'Invalid payment method.' );
							const validationMessage = cardValidation( company.metadata?.cardInfo && method === 'card',
								enqueueSnackbar,
								cardHolderName, cardHolderPhone,
								saveCard, cardFirstSix, cardLastFour );
							if ( validationMessage ) return;
							await confirm( {
								payment    : await onSubmit.current(),
								method,
								syncedOrder: order || newLocationOrder,
								paymentCreationDate,
							} );
						} else {
							// pay by tenders, saved card, selected client payments, and card connect ach
							if ( !memberCardNumber && isClientPage && payByTender ) throw new Error( 'Invalid payment method.' );
							
							// if member card number then check for the validation
							let totalSpentObj: {
								totalSpent: number | undefined,
								employeeId: string | undefined,
								houseAccountId: string | undefined
							} = {
								totalSpent    : undefined,
								employeeId    : undefined,
								houseAccountId: undefined,
							};
							if ( storeOrder && method === 'invoiss' && memberCardNumber ) {
								const { employeePayableCheck } = await queryGraphQL<ValidateEmployeeCardNumberQueryVariables, ValidateEmployeeCardNumberQuery>( {
									query: gql`
										query ValidateEmployeeCardNumber($cardNumber: String!, $companyId: String!, $orderTotal: Float!) {
												employeePayableCheck(cardNumber: $cardNumber, companyId: $companyId, orderTotal: $orderTotal) {
												     totalSpent
												     errorMessage
												    employeeId
												    houseAccountId
															}}
								  `,
									variables: {
										cardNumber: memberCardNumber,
										orderTotal: amount,
										companyId : company.id,
									},
								} );
								if ( employeePayableCheck?.errorMessage ) {
									enqueueSnackbar( employeePayableCheck.errorMessage, { variant: 'error' } );
									return;
								}
								totalSpentObj = {
									totalSpent    : employeePayableCheck?.totalSpent,
									employeeId    : employeePayableCheck?.employeeId,
									houseAccountId: employeePayableCheck?.houseAccountId,
								};
							}
							
							// sync invoice if type ACCOUNT, saved card, and no externalId
							let cloverOrder;
							if ( ( type === 'ACCOUNT' || storeOrder ) && !externalId && ( gateway?.external === 'CLOVER' || companyLocation?.gateway?.external === 'CLOVER' ) ) {
								try {
									const { data } = await axios.post( '/api/processor/manage/postOrder', {
										id,
										staffId  : staff?.id,
										gatewayId: gateway?.id || companyLocation?.gateway?.id,
									} );
									cloverOrder = data?.commerce;
									setOrder?.( data?.commerce );
									await wait( 600 );
								} catch {
									throw new Error( 'Error syncing order.' );
								}
								
							}
							
							let paymentData;
							
							try {
								enqueueSnackbar( t( 'commerce:payment-is-processing' ), { variant: 'info' } );
								
								// remove unnecessary cash discount
								if ( paidTotal === 0 && ( !method.includes( 'saved' ) || method === 'invoiss' ) && metadata.cashDiscount ) {
									try {
										await axios.post( '/api/tempCashDiscount', {
											id        : id,
											remove    : true,
											storeOrder: storeOrder,
										} );
										await queryClient.invalidateQueries( [ 'order' ] );
										enqueueSnackbar( t( 'commerce:cash-discount-not-applied' ), { variant: 'default' } );
										closeDrawer?.();
									} catch {
									}
								}
								
								if ( cashDiscount > 0 ) {
									if ( method.includes( 'saved' ) || method === 'invoiss' ) {
										cloverOrder = undefined;
									} else {
										const { data: commerce } = await axios.post( '/api/tempCashDiscount', {
											id: id,
											storeOrder,
										} );
										if ( commerce.commerce && commerce?.data ) {
											cloverOrder = commerce.commerce;
										}
									}
								} else {
									// apply card fee
									if ( !surchargePercent && !metadata?.enableCardFee && method.includes( 'saved' ) && cardType !== 'debit' && company.metadata.cardFee > 0 && !metadata?.cardFee && ( !storeOrder || storeOrder && cardFeeAllowedForStore ) ) {
										try {
											const { data: commerce } = await axios.post( '/api/tempCardFee', {
												id    : id,
												cardFee,
												storeOrder,
												paying: amount,
											} );
											if ( commerce.commerce && commerce?.data ) {
												cloverOrder = commerce.commerce;
											}
										} catch {
											enqueueSnackbar( t( 'commerce:card-fee-not-applied' ), { variant: 'default' } );
											closeDrawer?.();
											return;
										}
										
									}
								}
								
								// payment by client payment
								if ( selectedClientPayment ) {
									paymentData = await makePaymentWithClientPayment( {
										type: selectedClientPayment.type?.toUpperCase(),
										amount,
										note: getPaymentNote( {
											invoiceNote,
											note,
											checkNumber,
											locationPayment,
										} ),
										orderId        : invoiceId || cloverOrder?.id || id,
										gatewayId      : gateway?.id,
										companyId      : company.id,
										selectedClientPayment,
										metadata       : company?.metadata,
										staffExternalId: staff?.externalId || orderStaff?.externalId || undefined,
										staffId        : staff?.id,
										payerId        : staff?.id || orderClient?.id,
										payerName      : orderClient?.name || orderClient?.email || orderClient?.contact || ( staff?.user?.firstName || '' ) + ( staff?.user?.lastName || '' ),
										isClientPage,
										clientAddress,
										invoiceNumber,
									} );
								} else {
									paymentData = await makePayment( {
										type  : method.toUpperCase(),
										amount: amount,
										fee   : paymentCardFee,
										tip   : dollarTip || amount * tip / 100,
										note  : getPaymentNote( {
											invoiceNote,
											note,
											method,
											locationPayment,
											checkNumber,
										} ),
										signature      : signature,
										orderId        : invoiceId || cloverOrder?.id || id,
										gatewayId      : gateway?.id,
										companyId      : company.id,
										houseAccountId : totalSpentObj?.houseAccountId,
										employeeId     : totalSpentObj?.employeeId,
										metadata       : company?.metadata,
										staffExternalId: staff?.externalId || orderStaff?.externalId || null,
										staffId        : staff?.id,
										payerId        : staff?.id || orderClient?.id,
										payerName      : orderClient?.name || orderClient?.email || orderClient?.contact || ( staff?.user?.firstName || '' ) + ( staff?.user?.lastName || '' ),
										args           : {},
										cardToken      : cardToken || ( method?.includes( 'saved' )
											? method.split( '-' )?.[ 1 ]
											: undefined ),
										bankRoutingNbr : '',
										bankAccountNbr : '',
										bankAccountType: '',
										isClientPage   : isClientPage,
										memberCardNumber,
										clientAddress  : clientAddress,
										payerPostal    : payerPostalCode,
										payerCity      : payerCity,
										payerState     : payerState,
										payerLine1     : payerLine1,
										invoiceNumber  : invoiceNumber,
										selectedScheduledPayments,
									} );
									
									// if card member number then add a usage and spentToDate
									if ( storeOrder && method === 'invoiss' && memberCardNumber ) {
										await mutateGraphQL<EmployeeUsageTotalSpentMutationVariables, EmployeeUsageTotalSpentMutation>( {
											mutation: gql`
													mutation EmployeeUsageTotalSpent($id: String, $cardNumber: String, $orderId: String, $totalSpent: Float!, $amount: Float! ) {
																employeeUsageTotalSpent(id: $id, cardNumber: $cardNumber, orderId: $orderId, totalSpent: $totalSpent, amount: $amount)
											}`,
											variables: {
												cardNumber: memberCardNumber,
												orderId   : invoiceId || cloverOrder?.id || id,
												amount,
												totalSpent: totalSpentObj?.totalSpent || 0,
											},
										} ).catch( () => null );
									}
									
								}
								
							} catch ( e ) {
								const cloverErrors = e?.response?.data?.cloverErrors || e?.cloverErrors;
								
								if ( cloverErrors ) {
									throw typeof cloverErrors === 'string'
										? cloverErrors
										: cloverErrors?.error?.message || cloverErrors?.message || 'An error has occurred. Clover.com';
								} else {
									enqueueSnackbar( t( 'commerce:something-went-wrong' ), { variant: 'default' } );
								}
							}
							
							if ( paymentData && method.includes( 'saved' ) ) {
								await postCloverMeteredBilling( {
									orderId  : id,
									gatewayId: gateway?.id,
									eventType: 'Saved Card Payment',
									key      : storeOrder
										? 'onlineStoreOrders'
										: hasCardPayment
											? 'partialPayments'
											: 'invoicePayment',
									staffId : staff?.id,
									clientId: orderClient?.id,
								} ).catch( () => [] );
							}
							
							if ( paymentData ) await confirm( {
								payment    : paymentData?.payment,
								method,
								credits    : selectedCredits,
								syncedOrder: cloverOrder || order,
							} );
						}
					}}>
					{t( 'commerce:finish' )}
				</AsyncLoadingButton>
			</Stack>
		</Stack>
	);
}

export default function Wrapper( props: Props ) {
	const { external } = props.paymentGateway || {};
	if ( props.method === 'ach' && external === 'VOPAY' ) return <VopayPaymentDetails {...props}/>;
	if ( props.method === 'ach' && external === 'CARD_CONNECT' ) return <Ach {...props}/>;
	if ( props.method === 'ach' && external === 'PROPELR' ) return <CyberSourceACH {...props} />;
	return <PaymentDetails {...props}/>;
}
