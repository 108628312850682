import type { Card, Payments } from '@square/web-payments-sdk-types';
import Script from 'next/script';
import type { MutableRefObject } from 'react';
import { Fragment, useState } from 'react';
import type { Gateway } from '../../../../../types/schema';
import SquareButton from './button';
import SquareCard from './card';

export default function SquarePaymentDetails( { gateway, amount, method, createPayment, onSubmit, confirm }: {
	gateway: Gateway,
	amount: number,
	method: string,
	createPayment: ( data? ) => any,
	onSubmit: MutableRefObject<( () => Promise<string> )>,
	confirm: ( payment: string ) => void
} ) {
	const [ payments, setPayments ] = useState<Payments>( () =>
		window.Square?.payments( process.env.NEXT_PUBLIC_SQUARE_CLIENT_ID, gateway.externalId ) );
	const [ result, setResult ] = useState<Card>();
	
	switch ( method ) {
		case 'card':
			onSubmit.current = async () => {
				if ( !result ) throw new Error( 'No card entered' );
				const { errors, token } = await result.tokenize();
				if ( errors ) throw new Error( Object.values( errors ).join( ', ' ) );
				const { payment } = await createPayment( { cardId: token } );
				return payment;
			};
			
			return (
				<Fragment>
					<Script
						src={`https://${process.env.NEXT_PUBLIC_DEV ? 'sandbox.' : ''}web.squarecdn.com/v1/square.js`}
						onLoad={() => setPayments( () =>
							window.Square.payments( process.env.NEXT_PUBLIC_SQUARE_CLIENT_ID, gateway.externalId ) )}
					/>
					<SquareButton
						payments={payments}
						amount={amount}
						onPayment={async ( token ) => {
							const { payment } = await createPayment( { cardId: token } );
							confirm( payment );
						}}
					/>
					<SquareCard payments={payments} setResult={setResult}/>
				</Fragment>
			);
	}
	
	return null;
}
